import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import SettingNotificationSection from '../section/SettingNotificationSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
function HeaderSection({ shopId }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Link to={`/${shopId}/settings/notification/`} />

            <div className="page-common-title">Notification Setting</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopId}/settings/notification`,
                        title: 'Notification Setting',
                    },
                ]}
            />
        </div>
    );
}

const getNotifiData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SETTING_NOTIFICATION.replace(':shopId', params.shopId), {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, params, setNotifiListAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getNotifiData(params);
        setNotifiListAr(response.data.notificationList);
    } catch (err) {
        console.log(err);
    }
}
export default function SettingManageNotification() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const [notifiListAr, setNotifiListAr] = useState([]);

    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, { shopId }, setNotifiListAr);
    }, [dispatch, shopId]);

    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <HeaderSection shopId={shopId} />
                    <SettingNotificationSection
                        notifiListAr={notifiListAr}
                        shopId={shopId}
                        setNotifiListAr={setNotifiListAr}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
