import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconAccount from '@material-ui/icons/AccountBox';
import IconExitToApp from '@material-ui/icons/ExitToApp';
import IconHelpOutline from '@material-ui/icons/HelpOutline';
import IconVpnKey from '@material-ui/icons/VpnKey';
import React from 'react';
import { Link } from 'react-router-dom';

export default function AppBarUserOptions({ handleClose }) {
    return (
        <List component="nav" aria-label="main mailbox folders">
            <Link style={{ textDecoration: 'none' }} to="/user/info" onClick={handleClose}>
                <ListItem button>
                    <ListItemIcon>
                        <IconAccount />
                    </ListItemIcon>
                    <ListItemText>Account Information</ListItemText>
                </ListItem>
            </Link>
            <Link to="/user/change-password" onClick={handleClose}>
                <ListItem button>
                    <ListItemIcon>
                        <IconVpnKey />
                    </ListItemIcon>
                    <ListItemText>Change Password</ListItemText>
                </ListItem>
            </Link>
            <ListItem button>
                <ListItemIcon>
                    <IconHelpOutline />
                </ListItemIcon>
                <ListItemText primary="Support" />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <IconExitToApp />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    );
}
