import React from 'react';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';

export default function RewardManageHeaderSection({ shopInfo }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            <div className="page-common-title">Reward</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopInfo.id}/reward/`,
                        title: 'Reward',
                    },
                    {
                        href: `/${shopInfo.id}/reward/`,
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
