import { Assessment, Settings } from '@material-ui/icons';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import HornIcon from '../projects/utils/icons/HornIcon';
import ProductIcon from '../projects/utils/icons/ProductIcon';

const MenuData = [
    {
        sl: 10000001,
        parent_sl: 0,
        title: 'Dashboard',
        route: '/',
        icon: <DashboardIcon />,
    },
    {
        sl: 10000002,
        parent_sl: 0,
        title: 'Shop Overview',
        route: '/:shopId/overview/',
        icon: <StorefrontRoundedIcon />,
    },
    {
        sl: 10000003,
        parent_sl: 0,
        title: 'Products',
        route: '/:shopId/products/',
        icon: <ProductIcon />,
        subMenu: [
            {
                sl: 10000003,
                parent_sl: 10000002,
                title: 'Products',
                route: '/:shopId/products/manage/',
                icon: '',
            },
            {
                sl: 10000004,
                parent_sl: 10000002,
                title: 'Stock',
                route: '/:shopId/products/stock/',
                icon: '',
            },
            {
                sl: 10000005,
                parent_sl: 10000002,
                title: 'Categories',
                route: '/:shopId/products/category/',
                icon: '',
            },
            {
                sl: 10000033,
                parent_sl: 10000002,
                title: 'Feature Products',
                route: '/:shopId/products/feature/',
                icon: '',
            },
        ],
    },
    {
        sl: 10000006,
        parent_sl: 0,
        title: 'Orders',
        route: '/:shopId/orders/',
        icon: <AddShoppingCartRoundedIcon />,
        subMenu: [
            {
                sl: 10000007,
                parent_sl: 10000006,
                title: 'Recent Orders',
                route: '/:shopId/orders/recent/',
                icon: '',
            },
            {
                sl: 10000008,
                parent_sl: 10000006,
                title: 'Pending',
                route: '/:shopId/orders/pending/',
                icon: '',
            },
            {
                sl: 100000038,
                parent_sl: 10000006,
                title: 'Active',
                route: '/:shopId/orders/active/',
                icon: '',
            },
            {
                sl: 10000009,
                parent_sl: 10000006,
                title: 'Processing',
                route: '/:shopId/orders/processing/',
                icon: '',
            },
            {
                sl: 10000010,
                parent_sl: 10000006,
                title: 'Prepared',
                route: '/:shopId/orders/prepared/',
                icon: '',
            },
            {
                sl: 10000011,
                parent_sl: 10000006,
                title: 'Shipped',
                route: '/:shopId/orders/shipped/',
                icon: '',
            },
            {
                sl: 10000012,
                parent_sl: 10000006,
                title: 'Delivered',
                route: '/:shopId/orders/delivered',
                icon: '',
            },
            {
                sl: 10000013,
                parent_sl: 10000006,
                title: 'Canceled',
                route: '/:shopId/orders/canceled',
                icon: '',
            },
            {
                sl: 10000014,
                parent_sl: 10000006,
                title: 'Refunded',
                route: '/:shopId/orders/refunded',
                icon: '',
            },
        ],
    },
    {
        sl: 10000015,
        parent_sl: 0,
        title: 'Customers',
        route: '/:shopId/customers/',
        icon: <PeopleAltRoundedIcon />,
        subMenu: [
            {
                sl: 10000016,
                parent_sl: 10000015,
                title: 'Customers',
                route: '/:shopId/customers/manage/',
                icon: '',
            },
            {
                sl: 10000017,
                parent_sl: 10000015,
                title: 'Notify',
                route: '/:shopId/customers/notify/',
                icon: '',
            },
        ],
    },
    {
        sl: 10000018,
        parent_sl: 0,
        title: 'Finance & Account',
        route: '/:shopId/finance/',
        icon: <LocalAtmRoundedIcon />,
        subMenu: [
            {
                sl: 10000019,
                parent_sl: 10000018,
                title: 'Customers',
                route: '/:shopId/customers/manage/',
                icon: '',
            },
            {
                sl: 10000020,
                parent_sl: 10000018,
                title: 'Notify',
                route: '/:shopId/customers/notify/',
                icon: '',
            },
        ],
    },
    {
        sl: 10000021,
        parent_sl: 0,
        title: 'Campaigns',
        route: '/:shopId/campaigns/',
        icon: <HornIcon />,
    },
    {
        sl: 10000022,
        parent_sl: 0,
        title: 'Reports',
        route: '/:shopId/reports/',
        icon: <Assessment />,
    },
    {
        sl: 10000023,
        parent_sl: 0,
        title: 'Support',
        route: '/:shopId/support/',
        icon: <HelpOutlineIcon />,
    },
    {
        sl: 10000024,
        parent_sl: 0,
        title: 'Settings',
        route: '/:shopId/settings/',
        icon: <Settings />,
        subMenu: [
            {
                sl: 10000025,
                parent_sl: 10000024,
                title: 'User',
                route: '/:shopId/settings/user',
                icon: '',
            },
            {
                sl: 10000026,
                parent_sl: 10000024,
                title: 'Corn Jobs',
                route: '/:shopId/settings/corn-jobs',
                icon: '',
            },
            {
                sl: 10000027,
                parent_sl: 10000024,
                title: 'Permission',
                route: '/:shopId/settings/permission',
                icon: '',
            },
            {
                sl: 10000028,
                parent_sl: 10000024,
                title: 'Notification',
                route: '/:shopId/settings/notification/',
                icon: '',
            },
            {
                sl: 10000031,
                parent_sl: 10000024,
                title: 'Payment',
                route: '/:shopId/settings/payment/',
                icon: '',
            },
            {
                sl: 10000032,
                parent_sl: 10000024,
                title: 'Shipping Charge',
                route: '/:shopId/settings/shipping-charge/',
                icon: '',
            },
        ],
    },
    {
        sl: 10000029,
        parent_sl: 0,
        title: 'Rewards',
        route: '/:shopId/reward/',
        icon: <LoyaltyIcon />,
    },
    {
        sl: 10000030,
        parent_sl: 0,
        title: 'Stock',
        route: '/:shopId/stock/',
        icon: <LocalGroceryStoreIcon />,
    },
];

export default MenuData;
