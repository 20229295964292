import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../../../assets/css/store-select-section.css';
import ConfigApi from '../../../../configs/ConfigApi';
import RewardConfigData from '../../../../configs/RewardConfig';
import RewardDataDispatch from '../../../dispatches/RewardDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import DetectDevice from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import RewardProductListDesktopView from './RewardProductListDesktopView';
import RewardProductListMobileView from './RewardProductListMobileView';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    rootTwo: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
        marginTop: 15,
    },
}));

const updateRewardActive = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_ACTIVE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function RewardManageSection({ rewardproductData, productData }) {
    const classes = useStyles();
    const { shopId, shopInfo, shopData } = InitInfo();
    const dispatch = useDispatch();
    const rewardInfo = useSelector((state) => state.rewardInfo);

    const [rewardStatus, setRewardStatus] = React.useState(rewardInfo.is_active);

    const handleChange = () => {
        // console.dir(next);
        updateRewardActive({ shopId, rewardStatus: !rewardStatus }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                // --Update Store
                RewardDataDispatch(dispatch, {
                    is_active: !rewardStatus,
                    percent: rewardInfo.percent,
                    exp_date: rewardInfo.exp_date,
                });
            }
        });
        setRewardStatus(!rewardStatus);
    };
    return (
        <div className={classes.rootTwo}>
            <Grid container spacing={3}>
                <Grid key={RewardConfigData.id} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="prev-store">
                        <List className={classes.root}>
                            <ListItem button>
                                Reward Setting{' '}
                                <Link to={`/${shopId}/reward/update`}>
                                    <EditIcon style={{ marginLeft: '10px' }} />
                                </Link>
                                <FormGroup style={{ marginLeft: 'auto' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={rewardStatus}
                                                onChange={handleChange}
                                                name="checkedA"
                                            />
                                        }
                                        label={rewardStatus ? 'Stop Reward' : 'Start Reward'}
                                        labelPlacement="start"
                                        style={{ float: 'right' }}
                                    />
                                </FormGroup>
                            </ListItem>
                        </List>

                        <hr />

                        <List className={classes.root}>
                            <ListItem button key={1}>
                                <ListItemIcon>
                                    <MoneyOffIcon />
                                </ListItemIcon>
                                <ListItemText primary="Percent of Reward" />
                                <IconButton aria-label="delete" color="primary">
                                    <small>{rewardInfo.percent} %</small>
                                </IconButton>
                            </ListItem>
                        </List>
                        <Button variant="outlined" color="primary">
                            <Link to={`/${shopId}/reward/create/product/`}>Add Product</Link>
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <DetectDevice>
                <div type="lg" className={classes.tableContainer}>
                    <RewardProductListDesktopView
                        productData={productData}
                        rewardproductData={rewardproductData}
                        categoryList={shopData.categories}
                        shopInfo={shopInfo}
                    />
                </div>

                <div className="row" type="xs|sm" srtyle={{ marginTop: 10 }}>
                    <RewardProductListMobileView
                        productData={productData}
                        categoryList={shopData.categories}
                        rewardproductData={rewardproductData}
                        shopInfo={shopInfo}
                    />
                </div>
            </DetectDevice>
        </div>
    );
}
