import TabPanel from '@material-ui/lab/TabPanel';
import React from 'react';

export default function ProductPropertyDescription({ value, data }) {
    return (
        <TabPanel value={value}>
            <div className="row">
                <div className="col-12">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data?.product_description,
                        }}
                    />
                </div>
            </div>
        </TabPanel>
    );
}
