import {
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingSkuProduct = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_SKU_UPDATE.replace(':productId', params.productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        sku: params.sku,
                    });
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default function ProductSkuSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { shopInfo, productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [sku, setSku] = useState(productDetails.sku);

    const handleSku = (event) => {
        setSku(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingSkuProduct(dispatch, {
            sku,
            shopId: shopInfo.id,
            productId: productDetails.id,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            SKU Setup
                        </Typography>

                        <TextField
                            required
                            label="Product SKU"
                            variant="outlined"
                            fullWidth
                            type="number"
                            helperText="Please enter the product SKU"
                            onChange={handleSku}
                            value={sku}
                            size="small"
                        />
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={sku ? [1] : []}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
