import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';

const updatingVariantStock = ({ stockType, stockQty, variant, productId, shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_VARIANT_STOCK_UPDATE.replace(':productId', productId),
            {
                stockType,
                stockQty,
                variant,
                shopId,
            }
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function StockManageModal({ show, setStockModal, variant, productId }) {
    const { shopId } = InitInfo();
    const cuQuantity = variant?.stock || 0;
    const [type, setType] = useState('add');
    const [quantity, setQuantity] = useState(0);

    const handleVariantStock = () => {
        updatingVariantStock({
            stockType: type,
            stockQty: quantity,
            variant,
            productId,
            shopId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setStockModal(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleType = (ev) => {
        const typeVal = ev.target.value;
        setType(typeVal);
    };

    const handleQty = (ev) => {
        const qtVal = parseInt(ev.target.value || 0, 10);
        setQuantity(qtVal);
    };

    const stkInQty = type === 'add' ? cuQuantity + quantity : cuQuantity - quantity;

    return (
        <Modal
            show={show}
            onHide={() => setStockModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Stock Manage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="stock-modal-body">
                    <div className="stock-modal-body-info">
                        <div className="stock-modal-body-varient">
                            <ul>
                                <li>
                                    Color:
                                    <button type="button" style={{ background: variant?.color }} />
                                </li>
                                <li>
                                    Size: <span>{variant?.size}</span>
                                </li>
                                <li>
                                    Age: <span>{variant?.ageRange}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="stock-modal-input">
                            <Form.Group
                                aria-label="address type"
                                className="stock-modal-input-form-group"
                                onClick={handleType}
                            >
                                <Form.Check
                                    value="add"
                                    type="radio"
                                    label="Add Stock"
                                    name="stockType"
                                    checked={type === 'add'}
                                />
                                <Form.Check
                                    value="reduce"
                                    type="radio"
                                    label="Reduce Stock"
                                    name="stockType"
                                    checked={type === 'reduce'}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="formBasicEmail"
                                className="stock-modal-input-qty"
                            >
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Set Quantity"
                                    onChange={handleQty}
                                    value={quantity}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="stock-modal-body-stock">
                        <p>Total Stock</p>
                        <h5>{stkInQty}</h5>
                    </div>
                </div>
                <Form>
                    <button type="button" onClick={handleVariantStock} className="stock-modal-btn">
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default StockManageModal;
