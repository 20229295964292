/* eslint-disable no-await-in-loop */
import { Button, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { createRef, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../../configs/ConfigApi';
import AppInitDispatch from '../../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ShopProfile({ shopInfo }) {
    return (
        <table className="shop-profile-table">
            <tbody>
                <tr>
                    <td>Shop Name</td>
                    <td>{shopInfo.shop_name}</td>
                </tr>
                <tr>
                    <td>Shop Contact</td>
                    <td>{shopInfo.contact_number}</td>
                </tr>
                <tr>
                    <td>Shop Email</td>
                    <td>{shopInfo.email_address}</td>
                </tr>
                <tr>
                    <td>Shop Address</td>
                    <td>{shopInfo.address}</td>
                </tr>
            </tbody>
        </table>
    );
}

function BannerOption({
    sliderList,
    onImageRemove,
    uploadRef,
    handleOnFileChange,
    setMobileBannerId,
}) {
    return (
        <div className="banner-option">
            <div className="row">
                {sliderList?.map((item) => (
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6" key={item.id}>
                        <div className="banner-option-image">
                            <div className="banner-option-mobile">
                                {item.image_url_mobile ? (
                                    <>
                                        <div className="image-item-btn">
                                            <div className="image-item-btn">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => onImageRemove(item.id, 'mobile')}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <img src={item.image_url_mobile} alt="" />
                                    </>
                                ) : (
                                    <div className="banner-option-upload-btn">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            onClick={() => {
                                                setMobileBannerId(item.id);
                                                uploadRef.current.click();
                                            }}
                                            // {...dragProps}
                                            // style={isDragging ? { color: 'red' } : null}
                                        >
                                            <PhotoCamera />
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="image-item-btn">
                                <div className="image-item-btn">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => onImageRemove(item.id)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <img src={item.img_url} alt="" />
                        </div>
                    </div>
                ))}
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <div className="banner-option-upload-btn">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                            // {...dragProps}
                            // style={isDragging ? { color: 'red' } : null}
                        >
                            <PhotoCamera />
                        </Button>
                        <input
                            ref={uploadRef}
                            type="file"
                            name="file"
                            onChange={handleOnFileChange}
                            multiple
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg,image/jpg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

async function initData(dispatch, shopId, setSliderList) {
    try {
        await AppInitDispatch(dispatch, shopId);
        AxiosAuth.get(ConfigApi.API_SHOP_SLIDER_IMAGE.replace(':shopId', shopId))
            .then((response) => {
                setSliderList(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (err) {
        console.log(err);
    }
}

// const createSliderImg = ({ imageUrl, shopId, setSliderList }) => {

// };
export default function ShopDetailPropertyAppBar({ shopInfo }) {
    const dispatch = useDispatch();
    const uploadRef = createRef();

    const [sliderList, setSliderList] = useState();
    const [mobileBannerId, setMobileBannerId] = useState();

    const onImageRemove = (id, type) => {
        AxiosAuth.post(
            ConfigApi.API_SHOP_SLIDER_IMAGE_REMOVE.replace(':shopId', shopInfo.id).replace(
                ':imgId',
                id
            ),
            { type }
        )
            .then((response) => {
                setSliderList(response.data.images_ar);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    console.log(response.data.imageUrl);
                    if (response.data.error === 0) {
                        AxiosAuth.post(ConfigApi.API_SHOP_SLIDER_IMAGE_UPLOAD, {
                            imageUrl: response.data.imageUrl,
                            shopId: shopInfo.id,
                            mobileBannerId,
                        })
                            .then((slidData) => {
                                setSliderList(slidData.data.images_ar);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    useEffect(() => {
        initData(dispatch, shopInfo.id, setSliderList);
    }, [dispatch, shopInfo.id]);

    return (
        <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="shop-overview-header mb-3"
            >
                <Tab eventKey="home" title="Home">
                    <ShopProfile shopInfo={shopInfo} />
                </Tab>
                <Tab eventKey="profile" title="Profile">
                    <ShopProfile shopInfo={shopInfo} />
                </Tab>
                <Tab eventKey="contact" title="Banner">
                    <BannerOption
                        sliderList={sliderList}
                        onImageRemove={onImageRemove}
                        uploadRef={uploadRef}
                        handleOnFileChange={handleOnFileChange}
                        setMobileBannerId={setMobileBannerId}
                    />
                </Tab>
            </Tabs>
        </AppBar>
    );
}
