import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import RewardManagePage from '../app/Reward/Pages/RewardManagePage';

export default function RewardRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/:shopId/reward">
                <RewardManagePage key={location.key} />
            </Route>
            <Route path="/:shopId/reward/update/:modal">
                <RewardManagePage />
            </Route>

            <Route path="/:shopId/reward/create/product/:modal">
                <RewardManagePage />
            </Route>
            <Route path="/:shopId/reward/:rewardId/product/update/:modal">
                <RewardManagePage />
            </Route>
        </Switch>
    );
}
