import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import StatusText from '../../../utils/StatusText';
import OrderManageSection from '../section/OrderManageSection';
import OrderPageHeaderSection from '../section/OrderPageHeaderSection';

const pageTitle = 'Product Manage';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

const getProductData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_ORDERS.replace(':shopId', params.shopId), { params })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, params, setMemoAr, setMemoItemAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getProductData(params);
        setMemoAr(response.data.memoAr);
        setMemoItemAr(response.data.memoItemAr);
    } catch (err) {
        console.log(err);
    }
}
export default function OrderManagePage({ type }) {
    const classes = useStyles();

    const [pageNo] = useState(0);
    const dispatch = useDispatch();
    const [memoAr, setMemoAr] = useState([]);
    const [memoItemAr, setMemoItemAr] = useState([]);
    const history = useHistory();
    const { show, shopId, shopInfo } = InitInfo();
    const [page, setPage] = React.useState(0);

    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, { shopId, type }, setMemoAr, setMemoItemAr);
    }, [dispatch, history, pageNo, shopId, type]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    const title = <StatusText status={type} />;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    if (!memoAr) {
        return null;
    }
    // console.log(productData);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <OrderPageHeaderSection headTitle={type === 'recent' ? 'Recent' : title} />
                    {memoAr.length !== 0 ? (
                        memoAr &&
                        memoAr.map((item) => {
                            const memoItem = memoItemAr.filter(
                                (mItem) => mItem.memoId.toString() === item.id.toString()
                            );
                            return (
                                <Card className="order-card" key={item.id}>
                                    <Card.Body>
                                        <OrderManageSection
                                            memoData={item}
                                            memoItemData={memoItem}
                                            shopId={shopId}
                                        />
                                    </Card.Body>
                                </Card>
                            );
                        })
                    ) : (
                        <div className="order-section-no-order">
                            <div className="order-section-no-order-title">
                                <h5>There Are No Orders.</h5>
                                <h6>You can review and track your orders here.</h6>
                            </div>
                            <div className="order-section-no-order-img">
                                <img src={NoProductImg} alt="No Product" />
                            </div>
                        </div>
                    )}
                    {memoAr.length !== 0 ? (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={memoAr.length}
                            rowsPerPage={1}
                            page={page}
                            onChangePage={handleChangePage}
                        />
                    ) : (
                        ''
                    )}
                </MainSection>
            </div>
        </Preview>
    );
}
