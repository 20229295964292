import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import RewardAddProductModals from '../Section/RewardAddProductModals';
import RewardManageHeaderSection from '../Section/RewardManageHeaderSection';
import RewardManageSection from '../Section/RewardManageSection';
import RewardProductUpdateModals from '../Section/RewardProductUpdateModals';
import RewardUpdateModals from '../Section/RewardUpdateModals';

const pageTitle = 'Report Manage';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getProductData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_PRODUCTS, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const getRewardProductData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_REWARD_PRODUCT.replace(':shopId', params.shopId), {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setProductData, setRewardproductData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getProductData({ shopId });
        setProductData(response.data.products);
        const responseReward = await getRewardProductData({ shopId });
        setRewardproductData(responseReward.data);
    } catch (err) {
        console.log(err);
    }
}
export default function RewardManagePage() {
    const classes = useStyles();
    const history = useHistory();
    const rewardInfo = useSelector((state) => state.rewardInfo);

    const dispatch = useDispatch();
    const [rewardDataAr] = useState(rewardInfo);
    const { show, shopId, shopInfo } = InitInfo();
    const [productData, setProductData] = useState([]);
    const [rewardproductData, setRewardproductData] = useState([]);
    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, setProductData, setRewardproductData);
    }, [dispatch, history, shopId]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <RewardManageHeaderSection shopInfo={shopInfo} />
                    <RewardManageSection
                        rewardDataAr={rewardDataAr}
                        rewardproductData={rewardproductData}
                        productData={productData}
                    />
                </MainSection>
                <RewardUpdateModals />
                <RewardAddProductModals productData={productData} />
                <RewardProductUpdateModals rewardproductData={rewardproductData} />
            </div>
        </Preview>
    );
}
