import {
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingOfferPriceProduct = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_PRICE_OFFER_UPDATE.replace(':productId', params.productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        price_sale: params.productPrice,
                        vat_type: params.productVatType,
                        vat_percent: params.productVatPercent,
                    });
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default function ProductPriceOfferSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { shopInfo, productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [rawPrice] = useState(productDetails.price_sale);
    const [rawPricePercent] = useState(productDetails.vat_percent);
    const [offerPrice, setOfferPrice] = useState(productDetails.price_sale_offer);

    const handleOfferPercent = (event) => {
        const offPercent = event.target.value;
        setOfferPrice(rawPrice - (rawPrice * offPercent) / 100);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updatingOfferPriceProduct(dispatch, {
            offerPrice,
            shopId: shopInfo.id,
            productId: productDetails.id,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    console.log(offerPrice);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Set Offer Price Percent
                        </Typography>

                        <TextField
                            required
                            label="Offer Price Percent %"
                            variant="outlined"
                            fullWidth
                            type="number"
                            helperText="Please enter the product price"
                            onChange={handleOfferPercent}
                            size="small"
                        />
                        <table style={{ width: '100%', marginTop: 20 }}>
                            <thead>
                                <tr style={{ fontWeight: 'bold', height: 30 }}>
                                    <td>Type</td>
                                    <td>Original Price</td>
                                    <td>Offer Price </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ height: 30 }}>
                                    <td>No Vat</td>
                                    <td>{rawPrice}</td>
                                    <td>{offerPrice}</td>
                                </tr>
                                <tr style={{ height: 30 }}>
                                    <td>With Vat {rawPricePercent}%</td>
                                    <td>{(rawPrice * rawPricePercent) / 100 + rawPrice}</td>
                                    <td>{(offerPrice * rawPricePercent) / 100 + offerPrice}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={rawPrice ? [1] : []}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
