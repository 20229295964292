import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import PaymentSwitchBtn from '../comps/PaymentSwitchBtn';
import PaymentBasicConfigModal from '../modals/PaymentBasicConfigModal';
import PaymentConfigCurrencyModal from '../modals/PaymentConfigCurrencyModal';
import PaymentConfigModal from '../modals/PaymentConfigModal';

function PaymentConfig({
    setPaymentConfigAr,
    setMode,
    setType,
    setShow,
    setTitle,
    setConfigData,
    paymentConfigAr,
    getWayListAr,
    setBasicShow,
}) {
    return (
        <table className="w-100 setting-payment" style={{ marginTop: 20 }}>
            <thead>
                <tr>
                    <th>Payment Method</th>
                    <th colSpan={2}>Config</th>
                    <th>Is Show</th>
                    <th>is Enable</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(getWayListAr)?.map((row) => (
                    // console.log(row);
                    <tr key={row.vendor}>
                        <td>
                            <div className="setting-payment-title">
                                <img
                                    src={
                                        paymentConfigAr[row.vendor]?.icon
                                            ? paymentConfigAr[row.vendor]?.icon
                                            : row.icon
                                    }
                                    alt=""
                                />
                                {paymentConfigAr[row.vendor]?.vendor_title
                                    ? paymentConfigAr[row.vendor]?.vendor_title
                                    : row.title}
                            </div>
                        </td>
                        <td>
                            <button
                                type="button"
                                className="setting-payment-btn"
                                onClick={() => {
                                    setMode('develop');
                                    setType(row.vendor);
                                    setTitle(row.title);
                                    setConfigData(paymentConfigAr[row.vendor]?.api_json?.develop);
                                    setShow(true);
                                }}
                            >
                                Config Development
                            </button>
                        </td>
                        <td>
                            <button
                                type="button"
                                className="setting-payment-btn"
                                onClick={() => {
                                    setMode('production');
                                    setType(row.vendor);
                                    setTitle(row.title);
                                    setConfigData(
                                        paymentConfigAr[row.vendor]?.api_json?.production
                                    );
                                    setShow(true);
                                }}
                            >
                                Config Development
                            </button>
                        </td>
                        <td>
                            <div className="product-property-switch">
                                <PaymentSwitchBtn
                                    type={row.vendor}
                                    data={paymentConfigAr[row.vendor]?.is_enable}
                                    setPaymentConfigAr={setPaymentConfigAr}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="product-property-switch">
                                <PaymentSwitchBtn
                                    type={row.vendor}
                                    data={paymentConfigAr[row.vendor]?.is_enable}
                                    setPaymentConfigAr={setPaymentConfigAr}
                                />
                            </div>
                        </td>
                        <td>
                            <button
                                type="button"
                                className="btn btn-xs btn-info"
                                onClick={() => {
                                    setType(row.vendor);
                                    setBasicShow(true);
                                    setTitle(row.title);
                                    setConfigData(paymentConfigAr[row.vendor]);
                                }}
                            >
                                Edit
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
function PaymentCurrencyConfig({ shopId, currencyConfigAr, setCurrencyConfigAr }) {
    const [show, setShow] = useState(false);
    const [selectCurrency, setSelectCurrency] = useState('');
    const hnadleRemoveCurrency = (id) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_PAYMENT_CURRENCY_REMOVE.replace(':shopId', shopId).replace(
                ':currencyId',
                id
            )
        )
            .then((response) => {
                if (response.data.error === 0) {
                    setCurrencyConfigAr(response.data.currencyConfig_ar);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    let serial = 0;
    return (
        <>
            <button
                type="button"
                className="btn btn-xs btn-primary float-right m-4"
                onClick={() => setShow(true)}
            >
                Add New
            </button>
            <table className="w-100 setting-payment" style={{ marginTop: 20 }}>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Currency</th>
                        <th>Title</th>
                        <th>Amount</th>
                        <th>Default</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(currencyConfigAr)?.map((row) => {
                        // eslint-disable-next-line no-unreachable
                        serial += 1;
                        return (
                            <tr key={row.sl}>
                                <td>{serial}</td>
                                <td>{row.currency}</td>
                                <td>{row.title}</td>
                                <td>{row.rate}</td>
                                <td>{row.is_default}</td>
                                <td>
                                    {row.currency !== 'USD' ? (
                                        <>
                                            <EditIcon
                                                onClick={() => {
                                                    setShow(true);
                                                    setSelectCurrency(row);
                                                }}
                                            />
                                            <CloseIcon
                                                onClick={() => hnadleRemoveCurrency(row.sl)}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <PaymentConfigCurrencyModal
                show={show}
                setShow={setShow}
                shopId={shopId}
                setCurrencyConfigAr={setCurrencyConfigAr}
                selectCurrency={selectCurrency}
                setSelectCurrency={setSelectCurrency}
            />
        </>
    );
}
function SettingManagePaymentSection({
    shopId,
    paymentConfigAr,
    setPaymentConfigAr,
    currencyConfigAr,
    setCurrencyConfigAr,
    getWayListAr,
}) {
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('');
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [configData, setConfigData] = useState('');
    const [basicShow, setBasicShow] = useState(false);

    return (
        <>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="notification-overview-header mb-5"
            >
                <Tab eventKey="profile" title="Payment Method Config">
                    <PaymentConfig
                        setPaymentConfigAr={setPaymentConfigAr}
                        setMode={setMode}
                        setType={setType}
                        setShow={setShow}
                        setTitle={setTitle}
                        setConfigData={setConfigData}
                        paymentConfigAr={paymentConfigAr}
                        getWayListAr={getWayListAr}
                        setBasicShow={setBasicShow}
                    />
                </Tab>
                <Tab eventKey="contact" title="Payment Currency Config">
                    <PaymentCurrencyConfig
                        shopId={shopId}
                        currencyConfigAr={currencyConfigAr}
                        setCurrencyConfigAr={setCurrencyConfigAr}
                    />
                </Tab>
            </Tabs>
            {show ? (
                <PaymentConfigModal
                    show={show}
                    setShow={setShow}
                    shopId={shopId}
                    mode={mode}
                    type={type}
                    paymentConfigAr={paymentConfigAr?.value}
                    setPaymentConfigAr={setPaymentConfigAr}
                    title={title}
                    configData={configData}
                />
            ) : (
                ''
            )}
            {basicShow ? (
                <PaymentBasicConfigModal
                    show={basicShow}
                    setShow={setBasicShow}
                    shopId={shopId}
                    type={type}
                    title={title}
                    configData={configData}
                />
            ) : (
                ''
            )}
        </>
    );
}

export default SettingManagePaymentSection;
