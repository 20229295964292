import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import OrderConfirmModal from '../modals/OrderConfirmModal';
import OrderManageDetailSection from '../section/OrderMnageDetailSection';
import OrderPageDetailHeaderSection from '../section/OrderPageDetailHeaderSection';

const pageTitle = 'Product Manage';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getOrderDetail = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_ORDERS_DETAIL.replace(':shopId', params.shopId).replace(
                ':orderId',
                params.orderId
            )
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
async function initData(dispatch, shopId, params, setMemoAr, setMemoItemAr, setAddressAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getOrderDetail(params);
        setMemoAr(response.data.memoAr);
        setMemoItemAr(response.data.memoItemAr);
        setAddressAr(response.data.addressAr);
    } catch (err) {
        console.log(err);
    }
}

export default function OrderManageDetailPage({ type }) {
    const classes = useStyles();
    const { orderId } = useParams();
    const [pageNo] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const { show, shopId, shopInfo } = InitInfo();
    const [memoAr, setMemoAr] = useState([]);
    const [memoItemAr, setMemoItemAr] = useState([]);
    const [addressAr, setAddressAr] = useState([]);
    const [statusSet, setStatusSet] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const handleModal = () => {
        setStatusSet('');
    };
    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, { shopId, orderId }, setMemoAr, setMemoItemAr, setAddressAr);
    }, [dispatch, history, orderId, pageNo, shopId, type]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <OrderPageDetailHeaderSection
                        headTitle="Order Detail"
                        memoData={memoAr}
                        setStatusSet={setStatusSet}
                        setModalShow={setModalShow}
                    />
                    <OrderManageDetailSection
                        memoAr={memoAr}
                        memoItemAr={memoItemAr}
                        addressAr={addressAr}
                    />
                </MainSection>
                {statusSet && (
                    <OrderConfirmModal
                        modalShow={modalShow}
                        handleModal={handleModal}
                        memoData={memoAr}
                        memoItem={memoItemAr}
                        shopId={shopId}
                        orStatus={statusSet}
                    />
                )}
            </div>
        </Preview>
    );
}
