import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import SettingManageAccountPage from '../app/settings/pages/SettingManageAccountPage';
import SettingManagePasswordPage from '../app/settings/pages/SettingManagePasswordPage';
import AppBarSection from '../components/section/AppBarSection';
import MainSection from '../components/section/MainSection';
import NavigationDrawerSection from '../components/section/NavigationDrawerSection';
import ShopInfoDispatch from '../dispatches/ShopInfoDispatch';
import InitInfo from '../utils/InitInfo';
import Preview from '../utils/Preview';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

export default function UserRoutes() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { show, shopId, shopInfo } = InitInfo();
    const setOpen = useState(false)[1];

    useEffect(() => {
        ShopInfoDispatch(shopId, dispatch)
            .then(() => {
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, pathname, setOpen, shopId]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <Switch>
                        <Route path="/user/info" exact>
                            <SettingManageAccountPage />
                        </Route>
                        <Route path="/user/change-password" exact>
                            <SettingManagePasswordPage />
                        </Route>
                    </Switch>
                </MainSection>
            </div>
        </Preview>
    );
}
