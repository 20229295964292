import { Switch } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import BackdropOpenDispatch from '../../../dispatches/BackdropOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import InitInfo from '../../../utils/InitInfo';

const updateProductPublish = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_CONFIG.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function PaymentSwitchBtn({ data, type }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(data);
    const { shopInfo } = InitInfo();

    const handleChange = () => {
        const newVal = value !== true;
        BackdropOpenDispatch(dispatch, true);

        updateProductPublish({ newVal, shopId: shopInfo.id, type }, (response) => {
            DataErrors(response, dispatch);
            console.log(response);
            setValue(newVal);
            BackdropOpenDispatch(dispatch, false);
        });
    };

    return (
        <Switch
            checked={value}
            onChange={handleChange}
            color="primary"
            name="checkedB"
            value={value}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    );
}
